export interface BuiltFacet {
	storefrontId: string;
	topLevelFacets: Vendor.Facet[];
}

export const findFacet = <T extends Vendor.AnyFacet>(
	facets: T[],
	facetId: string,
): T | undefined => {
	return facets.find(
		(facet) => facet.id === facetId || findFacet(facet.facets, facetId),
	);
};

export const buildTopLevelFacets = (
	factors: API.VRMFactor[],
	vendorRawFacets: Vendor.AnyFacet[] = [],
): BuiltFacet[] => {
	const facets = factors.map((item) => {
		return {
			storefrontId: item.storefrontId,
			topLevelFacets: item.similarFacets
				.map((id) => findFacet(vendorRawFacets, id))
				.filter((facet): facet is Vendor.Facet => {
					return facet !== undefined;
				}),
		};
	});
	return facets;
};

export const matchFacetsWithStore = (
	builtFacets: BuiltFacet[],
	similarVendors: Vendor.Similar[],
) => {
	const facets = builtFacets.flatMap((facet) => {
		return similarVendors.flatMap((suggestion) => {
			if (facet.storefrontId.includes(suggestion.id)) {
				return {
					...suggestion,
					topLevelFacets: facet.topLevelFacets,
				};
			}
			return null;
		});
	});

	const filteredFacets = facets.filter((facet): facet is Vendor.Similar => {
		return facet !== null;
	});

	return filteredFacets;
};
