const desktopPillData: FilterPills.NewOrderPillDataStruct = {
	BAR: [
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Bar & Drinks', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Pill' },
	],
	BDS: [
		{ filterName: 'Music Genres', mode: 'Pill' },
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Wedding Activities', mode: 'Pill' },
		{ filterName: 'Instruments', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Side-nav' },
		{ filterName: 'Music Services', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
	],
	BEA: [
		{ filterName: 'Beauty', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Pill' },
		{ filterName: 'Fitness', mode: 'Pill' },
	],
	BWP: [
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Dresses', mode: 'Pill' },
		{ filterName: 'Fashion Services', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Pill' },
		{ filterName: 'Suits & Accessories', mode: 'Side-nav' },
		{ filterName: 'Accessories', mode: 'Side-nav' },
	],
	CAT: [
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Food & Catering', mode: 'Pill' },
		{ filterName: 'Cuisine', mode: 'Pill' },
		{ filterName: 'Dietary Options', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
	],
	DEC: [
		{ filterName: 'Decorations & Accents', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Lighting', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Pill' },
	],
	DJS: [
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Music Genres', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Pill' },
		{ filterName: 'Wedding Activities', mode: 'Side-nav' },
		{ filterName: 'Music Services', mode: 'Side-nav' },
	],
	DNC: [
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Music Genres', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Pill' },
	],
	ENS: [
		{ filterName: 'Instruments', mode: 'Pill' },
		{ filterName: 'Music Genres', mode: 'Pill' },
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Wedding Activities', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Music Services', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
	],
	FAV: [
		{ filterName: 'Gifts & Favors', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Pill' },
	],
	FLO: [
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Decorations & Accents', mode: 'Pill' },
		{ filterName: 'Flower Arrangements', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
	],
	INV: [
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Invitations & Paper Goods', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Pill' },
	],
	JWL: [
		{ filterName: 'Rings', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Wedding Jewelry', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Pill' },
	],
	OPC: [
		{ filterName: 'Ceremony Types', mode: 'Pill' },
		{ filterName: 'Religious Affiliations', mode: 'Pill' },
		{ filterName: 'Wedding Activities', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Planning', mode: 'Side-nav' },
	],
	PHB: [
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Photo & Video', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Pill' },
	],
	PLN: [
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Planning', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Wedding Activities', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
	],
	REC: [
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Guest Capacity', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Outdoor Space', mode: 'Pill' },
		{ filterName: 'Wedding Venue Amenities', mode: 'Pill' },
		{ filterName: 'Settings', mode: 'Side-nav' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Venue Service Offerings', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
		{ filterName: 'Affiliations', mode: 'Side-nav' },
	],
	RNT: [
		{ filterName: 'Rentals & Equipment', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Pill' },
	],
	SPV: [
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Guest Capacity', mode: 'Pill' },
		{ filterName: 'Wedding Activities', mode: 'Pill' },
		{ filterName: 'Settings', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Venue Service Offerings', mode: 'Side-nav' },
		{ filterName: 'Wedding Venue Amenities', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
	],
	TRA: [
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Transportation', mode: 'Pill' },
		{ filterName: 'Wedding Activities', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
		{ filterName: 'Service Staff', mode: 'Side-nav' },
	],
	TRV: [
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Planning', mode: 'Pill' },
		{ filterName: 'Wedding Activities', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Pill' },
	],
	VID: [
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Photo & Video', mode: 'Pill' },
		{ filterName: 'Photo & Video Styles', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
		{ filterName: 'Wedding Activities', mode: 'Side-nav' },
	],
	WCK: [
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Cakes & Desserts', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Dietary Options', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
	],
	WPH: [
		{ filterName: 'Starting Price Range', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Photo & Video Styles', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Pill' },
		{ filterName: 'Wedding Activities', mode: 'Side-nav' },
		{ filterName: 'Photo Shoot Types', mode: 'Side-nav' },
		{ filterName: 'Photo & Video', mode: 'Side-nav' },
	],
};

const mobilePillData: FilterPills.NewOrderPillDataStruct = {
	BAR: [
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Bar & Drinks', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
	],
	BDS: [
		{ filterName: 'Music Genres', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Price Range', mode: 'Side-nav' },
		{ filterName: 'Instruments', mode: 'Side-nav' },
		{ filterName: 'Wedding Activities', mode: 'Side-nav' },
		{ filterName: 'Distance', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
		{ filterName: 'Music Services', mode: 'Side-nav' },
	],
	BEA: [
		{ filterName: 'Beauty', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Fitness', mode: 'Side-nav' },
	],
	BWP: [
		{ filterName: 'Dresses', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Fashion Services', mode: 'Side-nav' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
		{ filterName: 'Suits & Accessories', mode: 'Side-nav' },
		{ filterName: 'Accessories', mode: 'Side-nav' },
	],
	CAT: [
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Food & Catering', mode: 'Side-nav' },
		{ filterName: 'Cuisine', mode: 'Side-nav' },
		{ filterName: 'Dietary Options', mode: 'Side-nav' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
	],
	DEC: [
		{ filterName: 'Decorations & Accents', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
		{ filterName: 'Lighting', mode: 'Side-nav' },
	],
	DJS: [
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Music Genres', mode: 'Side-nav' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
		{ filterName: 'Wedding Activities', mode: 'Side-nav' },
		{ filterName: 'Music Services', mode: 'Side-nav' },
	],
	DNC: [
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Music Genres', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
	],
	ENS: [
		{ filterName: 'Instruments', mode: 'Pill' },
		{ filterName: 'Music Genres', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Side-nav' },
		{ filterName: 'Wedding Activities', mode: 'Side-nav' },
		{ filterName: 'Price Range', mode: 'Side-nav' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
		{ filterName: 'Music Services', mode: 'Side-nav' },
	],
	FAV: [
		{ filterName: 'Gifts & Favors', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
	],
	FLO: [
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Decorations & Accents', mode: 'Side-nav' },
		{ filterName: 'Flower Arrangements', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
	],
	INV: [
		{ filterName: 'Invitations & Paper Goods', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
	],
	JWL: [
		{ filterName: 'Rings', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Side-nav' },
		{ filterName: 'Wedding Jewelry', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
	],
	OPC: [
		{ filterName: 'Ceremony Types', mode: 'Pill' },
		{ filterName: 'Religious Affiliations', mode: 'Pill' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
		{ filterName: 'Distance', mode: 'Side-nav' },
		{ filterName: 'Wedding Activities', mode: 'Side-nav' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Planning', mode: 'Side-nav' },
	],
	PHB: [
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Photo & Video', mode: 'Side-nav' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
	],
	PLN: [
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Planning', mode: 'Pill' },
		{ filterName: 'Wedding Activities', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Distance', mode: 'Side-nav' },
	],
	REC: [
		{ filterName: 'Guest Capacity', mode: 'Pill' },
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Outdoor Space', mode: 'Side-nav' },
		{ filterName: 'Wedding Venue Amenities', mode: 'Side-nav' },
		{ filterName: 'Distance', mode: 'Side-nav' },
		{ filterName: 'Settings', mode: 'Side-nav' },
		{ filterName: 'Venue Service Offerings', mode: 'Side-nav' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
		{ filterName: 'Affiliations', mode: 'Side-nav' },
	],
	RNT: [
		{ filterName: 'Rentals & Equipment', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
	],
	SPV: [
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Guest Capacity', mode: 'Pill' },
		{ filterName: 'Wedding Activities', mode: 'Side-nav' },
		{ filterName: 'Price Range', mode: 'Side-nav' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Settings', mode: 'Side-nav' },
		{ filterName: 'Venue Service Offerings', mode: 'Side-nav' },
		{ filterName: 'Wedding Venue Amenities', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
	],
	TRA: [
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Wedding Activities', mode: 'Pill' },
		{ filterName: 'Transportation', mode: 'Side-nav' },
		{ filterName: 'Distance', mode: 'Side-nav' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
		{ filterName: 'Service Staff', mode: 'Side-nav' },
	],
	TRV: [
		{ filterName: 'Wedding Activities', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Pill' },
		{ filterName: 'Planning', mode: 'Side-nav' },
		{ filterName: 'Distance', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
	],
	VID: [
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Wedding Activities', mode: 'Side-nav' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
		{ filterName: 'Photo & Video Styles', mode: 'Side-nav' },
		{ filterName: 'Photo & Video', mode: 'Side-nav' },
	],
	WCK: [
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Price Range', mode: 'Pill' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Cakes & Desserts', mode: 'Side-nav' },
		{ filterName: 'Dietary Options', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
	],
	WPH: [
		{ filterName: 'Starting Price Range', mode: 'Pill' },
		{ filterName: 'Distance', mode: 'Pill' },
		{ filterName: 'Wedding Activities', mode: 'Side-nav' },
		{ filterName: 'Best Of Weddings', mode: 'Side-nav' },
		{ filterName: 'Business Attributes', mode: 'Side-nav' },
		{ filterName: 'Photo & Video Styles', mode: 'Side-nav' },
		{ filterName: 'Photo Shoot Types', mode: 'Side-nav' },
		{ filterName: 'Photo & Video', mode: 'Side-nav' },
	],
};

export const newOrderPillData = (
	mobileUi = false,
): FilterPills.NewOrderPillDataStruct =>
	mobileUi ? mobilePillData : desktopPillData;
