export const openVrmModal = (): VRM.OpenModal => ({ type: 'vrm/OPEN_MODAL' });

export const closeVrmModal = (): VRM.CloseModal => ({
	type: 'vrm/CLOSE_MODAL',
});

export const setSimilarVendors = (
	vendors: Vendor.Similar[],
): VRM.SetSimilarVendors => ({
	type: 'vrm/SET_SIMILAR_VENDORS',
	payload: vendors,
});

export const setSimilarityFactors = (
	factors: API.VRMFactor[],
): VRM.SetSimilarityFactors => ({
	type: 'vrm/SET_SIMILARITY_FACTORS',
	payload: factors,
});

export const setMatchDetailsVisibility = (
	isMatchDetailsVisible: boolean,
): VRM.SetMatchDetailsVisibility => ({
	type: 'vrm/SET_MATCH_DETAILS_VISIBILITY',
	payload: isMatchDetailsVisible,
});
