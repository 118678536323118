import axios from '@axios';
import { reportServerSideExperiment } from '@redux/experiments';
import { selectValue } from '@redux/experiments/selectors';
import { experiments } from '@settings';
import { fetchSimilarVendors } from '../../containers/widget/actions';
import {
	setMatchDetailsVisibility,
	setSimilarVendors,
	setSimilarityFactors,
} from './actions';
import { FACET_PRIORITY_MAP } from './constants';
import { buildTopLevelFacets, matchFacetsWithStore } from './utils';

interface CityState {
	city: string | null;
	state: string | null;
}

const getCityState = (
	vendor: Vendor.Raw,
	searchLocation: Redux.Location,
	searchPageLocation: Redux.SearchPageLocation,
	pageType: Page.PageType,
): CityState => {
	if (pageType === 'city') {
		return { city: searchLocation.city, state: searchLocation.stateCode };
	}

	if (searchPageLocation) {
		return {
			city: searchPageLocation.city || null,
			state: searchPageLocation.stateCode || null,
		};
	}

	const { city, state } = vendor?.location?.address;
	return { city, state };
};

export const getSimilarVendors =
	(
		vendor: Vendor.Raw,
		vendorsRequestedLimit: number,
		radius: number,
		searchLocation: Redux.Location,
		searchPageLocation: Redux.SearchPageLocation,
		pageType: Page.PageType,
	): Redux.ThunkResult<Promise<void>> =>
	async (dispatch) => {
		// vrmApi version number
		const version = '2';

		try {
			const { city, state } = getCityState(
				vendor,
				searchLocation,
				searchPageLocation,
				pageType,
			);
			const { request } = fetchSimilarVendors(
				vendor,
				vendorsRequestedLimit,
				radius,
				{ city, state },
				version,
			);

			const { data } = request;
			const response = await axios.post<API.VrmSimilarResponse>(data);

			const profiles = shiftAiMainImage(
				response.data.data.similarity?.profiles,
			);

			const factors = response.data.data.similarity?.factors;
			if (!profiles || !profiles.length || !factors) {
				return;
			}

			dispatch(setSimilarVendors(profiles));
			dispatch(setSimilarityFactors(factors));
			dispatch(addTopLevelFacetsToSimilarVendors(vendor));
		} catch (error) {
			throw new Error(error).message;
		}
	};

export const addTopLevelFacetsToSimilarVendors =
	(vendorRaw: Vendor.Raw): Redux.ThunkResult<void> =>
	(dispatch, getState) => {
		const state = getState();
		const builtFacets = buildTopLevelFacets(
			state.vrm.factors,
			vendorRaw.facets,
		);
		const similarVendorsWithFacets = matchFacetsWithStore(
			builtFacets,
			state.vrm.similarVendors,
		);
		dispatch(setSimilarVendors(similarVendorsWithFacets));
	};

export const requestExperimentAssignment =
	(): Redux.ThunkResult<void> => (dispatch, getState) => {
		const state = getState();

		const isInTestGroup = selectValue(state, [
			'vrmMatchDetails',
			'show-match-details',
		]);
		const isAllowedVendorType = Object.keys(FACET_PRIORITY_MAP).includes(
			state.category.code,
		);

		const isMatchDetailsVisible = isInTestGroup && isAllowedVendorType;

		dispatch(setMatchDetailsVisibility(isMatchDetailsVisible));
		dispatch(reportServerSideExperiment(experiments.vrmMatchDetails));
	};

export const shiftAiMainImage = (
	profiles: Vendor.Similar[] | undefined,
): Vendor.Similar[] => {
	if (!profiles) {
		return [];
	}

	return profiles.map((profile: Vendor.Similar) => {
		const storefrontCard = profile.ai?.photo || profile.storefrontCard;

		return {
			...profile,
			storefrontCard,
		};
	});
};
