import { categorySlugsRegex } from '../../client/utils/categoryNameMappings';

const slugs = categorySlugsRegex.split('|').map((slug) => `${slug}-`);
const slugsRegEx = slugs.map((slug) => new RegExp(slug));

export const isFromSearchPage = (str = ''): boolean =>
	slugsRegEx.some((regEx) => {
		return !!str.match(regEx);
	});

export const canadianProvincesRegex = '(ab|bc|mb|n[bltsu]|on|pe|qc|sk|yt)$';

export function escapeRegExp(str: string) {
	return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
